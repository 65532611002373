import React from "react"
import Layout from "../../components/layout"
import { Grid } from "react-flexbox-grid"
import { HeroSection, Section } from "../../components/styledComponents"
import FeaturedProviders from "../../components/featuredProviders"

 const Page = props => {
  const { pageContext, scrollPosition } = props
  const { modules = {}, themeSettings = {} } = pageContext
  const { complianceMode = false } = themeSettings
  const { featuredProviders = [] } = modules
  return (
    <Layout {...props} headerBackground={"#fff"}>
      <HeroSection>
      </HeroSection>
      <Section>
        <Grid className="text-center">
          <h1>Page not found</h1>
          {!complianceMode ? (
            <FeaturedProviders
              {...props}
              providers={featuredProviders?.slice(0, 5) || []}
              scrollPosition={scrollPosition}
            />
          ) : null}
        </Grid>
      </Section>
    </Layout>
  )
}

export default Page
